import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import "./App.css";



function ProductDisplay(props) { 

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById("form");
    ReactGA.send({ hitType: "pageview", page: "/open_pay", title: "open_pay" });
    form.submit();
  }

 
    return (
    <section>
      <form action="https://api.thissitesaysimthegreatest.com/create-checkout-session" method="POST" id="form" onSubmit={handleSubmit}>
      <input type="hidden" name="value" value={props.value} />
          <button type="submit" className="button-52"  >
            {props.message !== "" ? props.message : "Claim your Greatness"}
        </button>
      </form>
    </section>
  );
}



export default function App(props) {
  const [message, setMessage] = useState("");
  
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("We Have a new Champion!");
      ReactGA.send({ hitType: "pageview", page: "/changed", title: "changed" });
    }
    if (query.get("canceled")) {
      ReactGA.send({ hitType: "pageview", page: "/chicken", title: "chicken" });
      setMessage(
        "I guess you're not the greatest"
      );
    }
    setTimeout(() => setMessage(''), 5000);
    ReactGA.initialize("G-8GJFKRGHEP");


  }, []);

  return (
    <>
      <ProductDisplay value={props.value} setM={setMessage} message={message} />
    </>
  );
}
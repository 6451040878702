import { useEffect,useState,useCallback } from 'react';
import ReactGA from "react-ga4";

import './App.css';
import './CheckoutForm'
import manifesto from './manifesto.png'
import CheckoutForm from './CheckoutForm';



function App() {

  const [json, setJson] = useState("*Loading*");
  const [showM, setShowM] = useState(false);
  


  const fetchData = useCallback(() => {
    fetch('https://api.thissitesaysimthegreatest.com/goat', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    }).then(response => response.json())
      .then((response) => {
         setJson(response.name)
      }).catch((e) => console.log(e));
  },[]) 

  useEffect(() => {  
    fetchData();
    ReactGA.initialize("G-8GJFKRGHEP");
  }, [fetchData])


const openM = event => {
  console.log('Image clicked');
  ReactGA.send({ hitType: "pageview", page: "/open_Manif", title: "open_Manif" });
  setShowM(true);
};
  
const closeM = event => {
  console.log('Image clicked');
  ReactGA.send({ hitType: "pageview", page: "/close_Manif", title: "close_Manif" });
  setShowM(false);
};
  
  return (
    <div className="App">
      <div className="div1">
        <img src={manifesto} alt="Logo" className='manifIcon' onClick={(e) => { openM(e) }} draggable="false" />
      </div>
      
      <div className='div2'>
        <span className='name'>{json} {json !== "*Loading*" ? "is" : "" }</span>
        the Greatest
      <div className="div3">*Yes, on the internet you can be the greatest for $1.99</div>
      </div>
      <div className="div4">
        <CheckoutForm />
      </div> 
      { showM ?   
        <div className='Manifesto' onClick={(e) => { closeM(e) }} >
          <div className='inside'>
          The digital ego has become too powerful. Behind a keyboard we are all incredible, huge, invincible.
          We live from this thirst to be right, and the internet allows us to be right all the time. We're right about soccer, 
          about politics, about international affairs, we're right about everything, and even when we're not,
          being right is just a logoff away, the people that are wrong ( everybody else ) cant take it from us if we're not there, right?
         <br /><br />
          On the internet we're all the greatest, this site just wants to demonstrate the fragility of that greatness,
          <br /><br />
          For just $1.99 you can have a site that says you're the greatest, your name will be on it forever  
          or until someone thinks they're greater than you. 
            <br /><br />
            That's how ThisSiteSaysImTheGreatest.com was born (that and because I needed to test the payment system of a project I'm currenlty working on)
            <br /><br />
            Barbosa 2023
          </div>
      </div>
        : null }
      <div className="div5"> A project by <a href="http://xxx.vasco.xxx" target="_blank" rel="noreferrer" >Barbosa</a> 2023 </div>
      
    </div>
      );
}

export default App;
